<template>
  <div class="container">
    <header>
      <img src="https://eps-staffing.com/wp-content/uploads/2024/04/logo.png-heafer.png" alt="EPS Staffing Logo"
           class="header-logo">
    </header>

    <div v-if="!formSubmitted">
      <h1>Employment Application</h1>
<!--      <button @click="fillTestData" type="button" class="test-btn">Fill with Test Data</button>-->

      <div class="progress-bar">
        <div class="progress-step" :class="{ 'active': currentStep >= 1, 'complete': currentStep > 1 }">
          <div class="step-number">1</div>
          <div class="step-name">Personal Info</div>
        </div>
        <div class="progress-step" :class="{ 'active': currentStep >= 2, 'complete': currentStep > 2 }">
          <div class="step-number">2</div>
          <div class="step-name">Education</div>
        </div>
        <div class="progress-step" :class="{ 'active': currentStep >= 3 ,'complete': currentStep > 3}">
          <div class="step-number">3</div>
          <div class="step-name">Work History</div>
        </div>
        <div class="progress-step" :class="{ 'active': currentStep >= 4 }">
          <div class="step-number">4</div>
          <div class="step-name">Acknowledgement</div>
        </div>
      </div>
      <form @submit.prevent="submitForm">
        <div v-if="currentStep === 1">
          <h2>Step 1: Personal Information</h2>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="firstName">First Name:</label>
                <input v-model="formData.personalInfo.firstName" id="firstName" type="text" required
                       @blur="validateField('personalInfo', 'firstName')">
                <span class="error" v-if="errors.personalInfo.firstName">{{ errors.personalInfo.firstName }}</span>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="lastName">Last Name:</label>
                <input v-model="formData.personalInfo.lastName" id="lastName" type="text" required
                       @blur="validateField('personalInfo', 'lastName')">
                <span class="error" v-if="errors.personalInfo.lastName">{{ errors.personalInfo.lastName }}</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label for="address1">Address 1:</label>
                <textarea v-model="formData.personalInfo.address1" id="address1" rows="3" required
                          @blur="validateField('personalInfo', 'address1')"></textarea>
                <span class="error" v-if="errors.personalInfo.address1">{{ errors.personalInfo.address1 }}</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label for="address2">Address 2:</label>
                <textarea v-model="formData.personalInfo.address2" id="address2" rows="3"
                          @blur="validateField('personalInfo', 'address2')"></textarea>
                <span class="error" v-if="errors.personalInfo.address2">{{ errors.personalInfo.address2 }}</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-4">
              <div class="form-group">
                <label for="city">City:</label>
                <input v-model="formData.personalInfo.city" id="city" type="text" required
                       @blur="validateField('personalInfo', 'city')">
                <span class="error" v-if="errors.personalInfo.city">{{ errors.personalInfo.city }}</span>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="form-group">
                <label for="state">State:</label>
                <select v-model="formData.personalInfo.state" id="state" required
                        @blur="validateField('personalInfo', 'state')">
                  <option value="">Select a state</option>
                  <option v-for="state in states" :key="state" :value="state">{{ state }}</option>
                </select>
                <span class="error" v-if="errors.personalInfo.state">{{ errors.personalInfo.state }}</span>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="form-group">
                <label for="zip">ZIP:</label>
                <input v-model="formData.personalInfo.zip" id="zip" type="text" required
                       @blur="validateField('personalInfo', 'zip')">
                <span class="error" v-if="errors.personalInfo.zip">{{ errors.personalInfo.zip }}</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-4">
              <div class="form-group">
                <label for="homePhone">Home Phone:</label>
                <input
                    v-model="formData.personalInfo.homePhone"
                    id="homePhone"
                    type="tel"
                    placeholder="### ### ####"
                    maxlength="12"
                    @input="formData.personalInfo.homePhone = formatPhoneNumber($event.target.value)"
                    @blur="validateField('personalInfo', 'homePhone')"
                >
                <span class="error" v-if="errors.personalInfo.homePhone">{{ errors.personalInfo.homePhone }}</span>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="form-group">
                <label for="mobilePhone">Mobile Phone:</label>
                <input
                    v-model="formData.personalInfo.mobilePhone"
                    id="mobilePhone"
                    type="tel"
                    maxlength="12"
                    placeholder="### ### ####"
                    @input="formData.personalInfo.mobilePhone = formatPhoneNumber($event.target.value)"
                    @blur="validateField('personalInfo', 'mobilePhone')"
                    required
                >
                <span class="error" v-if="errors.personalInfo.mobilePhone">{{ errors.personalInfo.mobilePhone }}</span>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="form-group">
                <label for="emailAddress">Email Address:</label>
                <input v-model="formData.personalInfo.emailAddress" id="emailAddress" type="email" required
                       @blur="validateField('personalInfo', 'emailAddress')">
                <span class="error" v-if="errors.personalInfo.emailAddress">{{
                    errors.personalInfo.emailAddress
                  }}</span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="form-group">
               <div class="small-text d-block">
                 <!--<p>
                 Eclipse Professional Services utilizes contact information solely in the hopes of employing and
                 placing applicants to our site. As this is our primary objective, Eclipse Professional Services
                 does not and will not sell any of your contact information.</p>
                 <p>
                 By electing to Accept SMS Communications, you have agreed to accept SMS Messages from
                 Eclipse Professional Services related to Job Opportunities and Employment.
                 </p>-->

                 <p>
                  Eclipse Professional Services utilizes contact information solely in the hopes of employing and placing applicants to our site. 
                  As this is our primary objective, Eclipse Professional Services does not and will not sell any of your contact information.
                </p>

                <p>
                  By electing to Accept SMS Communications, you have agreed to accept SMS Messages from Eclipse Professional Services related to Job Opportunities and Employment.
                </p>

                <p>
                  Message and Data Rates may apply. You can STOP messaging by sending STOP and get more help by sending HELP.
                </p>

                <p>
                  You can review our Privacy Policy at <a href="https://eps-staffing.com/privacy-policy/" target="_blank">https://eps-staffing.com/privacy-policy/</a>.
                </p>

               </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label for="acceptSMS">Willing to Accept SMS Communications:</label>
                <select v-model="formData.personalInfo.acceptSMS" id="acceptSMS" required @blur="validateField('personalInfo', 'acceptSMS')">
                  <option value="">Select a value</option>
                  <option v-for="acceptSMS in acceptsSMS" :key="acceptSMS" :value="acceptSMS">{{ acceptSMS }}</option>
                </select>
                <span class="error" v-if="errors.personalInfo.acceptSMS">{{ errors.personalInfo.acceptSMS }}</span>
              </div>
            </div>
          </div>
        </div>

        <div v-if="currentStep === 2">
          <h2>Step 2: Educational Information</h2>
          <div>
            Most Schools begin in August and end in May. If you are not able to get the exact Dates use 8/1 with the
            year you began and 5/1 and the year you completed
          </div>
          <br/>
          <div v-for="(edu, index) in formData.educationHistory" :key="index" class="education-entry">
            <button @click="removeEducation(index)" type="button" class="delete-btn"
                    v-if="formData.educationHistory.length > 1">
              <span className="pi pi-trash"></span>
            </button>

            <h3>Education {{ index + 1 }}</h3>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label :for="`edu-${index}-diplomaOrDegree`">Diploma/Degree:</label>
                  <!--<input v-model="edu.diplomaOrDegree" :id="`edu-${index}-diplomaOrDegree`" type="text" required @blur="validateField('educationHistory', 'diplomaOrDegree', index)">-->
                  <select v-model="edu.diplomaOrDegree" :id="`edu-${index}-diplomaOrDegree`" required
                          @blur="validateField('educationHistory', 'diplomaOrDegree', index)">
                    <option value="">Select a degree</option>
                    <option v-for="degree in diplomaOrDegreeTypes" :key="degree" :value="degree">{{ degree }}</option>
                  </select>
                  <span class="error"
                        v-if="errors.educationHistory[index] && errors.educationHistory[index].diplomaOrDegree">
                  {{ errors.educationHistory[index].diplomaOrDegree }}
                </span>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label :for="`edu-${index}-schoolName`">School Name:</label>
                  <input v-model="edu.schoolName" :id="`edu-${index}-schoolName`" type="text" required
                         @blur="validateField('educationHistory', 'schoolName', index)">
                  <span class="error"
                        v-if="errors.educationHistory[index] && errors.educationHistory[index].schoolName">
                  {{ errors.educationHistory[index].schoolName }}
                </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label :for="`edu-${index}-schoolCity`">School City:</label>
                  <input v-model="edu.schoolCity" :id="`edu-${index}-schoolCity`" type="text" required
                         @blur="validateField('educationHistory', 'schoolCity', index)">
                  <span class="error"
                        v-if="errors.educationHistory[index] && errors.educationHistory[index].schoolCity">
                  {{ errors.educationHistory[index].schoolCity }}
                </span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label :for="`edu-${index}-schoolState`">School State:</label>
                  <!--<input v-model="edu.schoolState" :id="`edu-${index}-schoolState`" type="text" required @blur="validateField('educationHistory', 'schoolState', index)">-->
                  <select v-model="edu.schoolState" :id="`edu-${index}-schoolState`" required
                          @blur="validateField('educationHistory', 'schoolState', index)">
                    <option value="">Select a state</option>
                    <option v-for="state in states" :key="state" :value="state">{{ state }}</option>
                  </select>
                  <span class="error"
                        v-if="errors.educationHistory[index] && errors.educationHistory[index].schoolState">
                  {{ errors.educationHistory[index].schoolState }}
                </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label :for="`edu-${index}-startDate`">Start Date:</label>
                  <!--                <input v-model="edu.startDate" :id="`edu-${index}-startDate`" type="date" required @blur="validateField('educationHistory', 'startDate', index)" >-->
                  <VueDatePicker v-model="edu.startDate" :id="`edu-${index}-startDate`" placeholder="Select Date"
                                 :enable-time-picker="false"
                                 model-type="M/dd/yyyy" @blur="validateField('educationHistory', 'startDate', index)"
                                 auto-apply required></VueDatePicker>
                  <span class="error" v-if="errors.educationHistory[index] && errors.educationHistory[index].startDate">
                  {{ errors.educationHistory[index].startDate }}
                </span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label :for="`edu-${index}-endDate`">End Date:</label>
                  <!--  <input v-model="edu.endDate" :id="`edu-${index}-endDate`" type="date" required @blur="validateField('educationHistory', 'endDate', index)">-->
                  <VueDatePicker v-model="edu.endDate" :id="`edu-${index}-endDate`" placeholder="Select Date"
                                 :enable-time-picker="false"
                                 model-type="M/dd/yyyy" @blur="validateField('educationHistory', 'endDate', index)"
                                 auto-apply required></VueDatePicker>
                  <span class="error" v-if="errors.educationHistory[index] && errors.educationHistory[index].endDate">
                  {{ errors.educationHistory[index].endDate }}
                </span>
                </div>
              </div>
            </div>
          </div>
          <button @click="addEducation" type="button" class="add-btn">Add More</button>
        </div>

        <div v-if="currentStep === 3">
          <h2>Step 3: Work History</h2>
          <div v-for="(emp, index) in formData.employmentHistory" :key="index" class="employment-entry">
            <button @click="removeEmployment(index)" type="button" class="delete-btn"
                    v-if="formData.employmentHistory.length > 1">
              <span className="pi pi-trash"></span>
            </button>
            <h3>Employment {{ index + 1 }}</h3>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label :for="`emp-${index}-companyName`">Company Name:</label>
                  <input v-model="emp.companyName" :id="`emp-${index}-companyName`" type="text" required
                         @blur="validateField('employmentHistory', 'companyName', index)">
                  <span class="error"
                        v-if="errors.employmentHistory[index] && errors.employmentHistory[index].companyName">
                  {{ errors.employmentHistory[index].companyName }}
                </span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label :for="`emp-${index}-city`">City:</label>
                  <input v-model="emp.city" :id="`emp-${index}-city`" type="text" required
                         @blur="validateField('employmentHistory', 'city', index)">
                  <span class="error" v-if="errors.employmentHistory[index] && errors.employmentHistory[index].city">
                  {{ errors.employmentHistory[index].city }}
                </span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label :for="`emp-${index}-state`">State:</label>
                  <!--<input v-model="emp.state" :id="`emp-${index}-state`" type="text" required @blur="validateField('employmentHistory', 'state', index)">-->
                  <select v-model="emp.state" :id="`emp-${index}-state`" required
                          @blur="validateField('employmentHistory', 'state', index)">
                    <option value="">Select a state</option>
                    <option v-for="state in states" :key="state" :value="state">{{ state }}</option>
                  </select>
                  <span class="error" v-if="errors.employmentHistory[index] && errors.employmentHistory[index].state">
                  {{ errors.employmentHistory[index].state }}
                </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label :for="`emp-${index}-startDate`">Start Date:</label>
                  <!--<input v-model="emp.startDate" :id="`emp-${index}-startDate`" type="date" required @blur="validateField('employmentHistory', 'startDate', index)">-->
                  <VueDatePicker v-model="emp.startDate" :id="`emp-${index}-startDate`" placeholder="Select Date"
                                 :enable-time-picker="false"
                                 model-type="M/dd/yyyy" @blur="validateField('employmentHistory', 'startDate', index)"
                                 auto-apply required></VueDatePicker>
                  <span class="error"
                        v-if="errors.employmentHistory[index] && errors.employmentHistory[index].startDate">
                  {{ errors.employmentHistory[index].startDate }}
                </span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label :for="`emp-${index}-endDate`">End Date:</label>
                  <!--<input v-model="emp.endDate" :id="`emp-${index}-endDate`" type="date" required @blur="validateField('employmentHistory', 'endDate', index)">-->
                  <VueDatePicker v-model="emp.endDate" :id="`emp-${index}-endDate`" placeholder="Select Date"
                                 :enable-time-picker="false"
                                 model-type="M/dd/yyyy" @blur="validateField('employmentHistory', 'endDate', index)"
                                 auto-apply required></VueDatePicker>
                  <span class="error" v-if="errors.employmentHistory[index] && errors.employmentHistory[index].endDate">
                  {{ errors.employmentHistory[index].endDate }}
                </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label :for="`emp-${index}-jobTitle`">Job Title:</label>
                  <input v-model="emp.jobTitle" :id="`emp-${index}-jobTitle`" type="text" required
                         @blur="validateField('employmentHistory', 'jobTitle', index)">
                  <span class="error"
                        v-if="errors.employmentHistory[index] && errors.employmentHistory[index].jobTitle">
                  {{ errors.employmentHistory[index].jobTitle }}
                </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label :for="`emp-${index}-jobDuties`">Job Duties:</label>
                  <textarea v-model="emp.jobDuties" :id="`emp-${index}-jobDuties`" rows="3" required
                            @blur="validateField('employmentHistory', 'jobDuties',index)"></textarea>
                  <span class="error"
                        v-if="errors.employmentHistory[index] && errors.employmentHistory[index].jobDuties">
                  {{ errors.employmentHistory[index].jobDuties }}
                </span>
                </div>
              </div>
            </div>
            <!--<button @click="removeEmployment(index)" type="button" class="remove-btn" v-if="formData.employmentHistory.length > 1">Remove</button>-->
          </div>
          <button @click="addEmployment" type="button" class="add-btn">Add More</button>
        </div>

        <div v-if="currentStep === 4">
          <h2>Step 4: Acknowledgement</h2>
          <div class="acknowledgement-text">
            <p>I certify that all the information provided in this Job Application and possibly secure employment with Eclipse Professional Services is true, complete and correct.  I understand that all the information that I have provided is subject to verification and that any information found to be false, incomplete or misrepresented in any respect, will be sufficient cause to cancel further consideration of this application or if hired, I may be subject to discharge of employment.
            </p>
            <p>
              I have expressly authorized, without reservation Eclipse Professional Services, it representatives, employees or agents to contact all references and any other persons in order to obtain any and all information deemed necessary by them to verify the accuracy of all information provided by me in this application or at other points throughout the entire process including interview.
            </p>
            <p>
              I understand that Eclipse Professional Services does not unlawfully discriminate in employment and no question on this application is used for the purpose of limiting or excluding any applicant from consideration on a basis by applicable local, state or federal law.</p>
          </div>
          <div class="form-group">
            <label for="signature">Signature:</label>
            <SignatureComponent v-model="formData.signature" />
            <span class="error" v-if="errors.signature">{{ errors.signature }}</span>
          </div>
        </div>


        <div class="button-group">
          <button v-if="currentStep > 1" @click="prevStep" type="button" class="prev-btn" :disabled="isLoading">
            Previous
          </button>
          <button v-if="currentStep < 4" @click="nextStep" type="button" class="next-btn" :disabled="isLoading">Next
          </button>
          <button v-if="currentStep === 4" type="submit" class="submit-btn" :disabled="isLoading">
            <span v-if="isLoading">Submitting...</span>
            <span v-else>Submit</span>
          </button>
        </div>
      </form>
    </div>

    <div v-else class="success-message">
      <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none" stroke="#00A651"
           stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="success-icon">
        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
        <polyline points="22 4 12 14.01 9 11.01"></polyline>
      </svg>
      <h2>Thank You!</h2>
      <p>Your form has been submitted successfully.</p>
    </div>


    <div v-if="submitError" class="alert error">
      {{ submitError }}
    </div>

    <!-- Loading Overlay -->
    <div v-if="isLoading" class="loading-overlay">
      <div class="loading-spinner"></div>
    </div>
  </div>
</template>

<script setup>
import {ref, reactive} from 'vue';
import axios from 'axios';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import 'primeicons/primeicons.css'
//import SignaturePad from 'signature_pad';
import SignatureComponent from './components/AFSignatureComponent.vue';


const currentStep = ref(1);
const isLoading = ref(false);
const formSubmitted = ref(false);
const submitError = ref(null);



const formData = reactive({
  personalInfo: {
    firstName: '',
    lastName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    homePhone: '',
    mobilePhone: '',
    emailAddress: '',
    acceptSMS: ''
  },
  educationHistory: [{
    diplomaOrDegree: '',
    schoolName: '',
    schoolCity: '',
    schoolState: '',
    startDate: '',
    endDate: ''
  }],
  employmentHistory: [{
    companyName: '',
    city: '',
    state: '',
    //endDatestartDate: '',
    endDate: '',
    jobTitle: '',
    jobDuties: ''
  }]
});

const errors = reactive({
  personalInfo: {},
  educationHistory: [{}],
  employmentHistory: [{}],
  signature: ''
});

/*const states = [
  'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia',
  'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland',
  'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey',
  'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina',
  'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
];*/

const states = [
  'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
  'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
  'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
  'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
  'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY', 'Other'
];

const acceptsSMS = [
  'Yes, I have Opted In to Accept SMS Messages', 'No, I have Opted not to Accept SMS Messages'
];

const diplomaOrDegreeTypes = [
  'GED/High School Diploma', 'Some College', "Associate's Degree", "Bachelor's Degree", "Master's Degree", 'Doctrite'
];

const isRequired = (key) => {
  return !['address2', 'homePhone'].includes(key);
};

const addEducation = () => {
  formData.educationHistory.push({
    diplomaOrDegree: '',
    schoolName: '',
    schoolCity: '',
    schoolState: '',
    startDate: '',
    endDate: ''
  });
  errors.educationHistory.push({});
};

const removeEducation = (index) => {
  if (formData.educationHistory.length > 1) {
    formData.educationHistory.splice(index, 1);
    errors.educationHistory.splice(index, 1);
  }
};

const addEmployment = () => {
  formData.employmentHistory.push({
    companyName: '',
    city: '',
    state: '',
    startDate: '',
    endDate: '',
    jobTitle: '',
    jobDuties: ''
  });
  errors.employmentHistory.push({});
};

const removeEmployment = (index) => {
  if (formData.employmentHistory.length > 1) {
    formData.employmentHistory.splice(index, 1);
    errors.employmentHistory.splice(index, 1);
  }
};

const validateField = (section, key, index) => {
  let value = index !== undefined ? formData[section][index][key] : formData[section][key];
  let error = '';

  if (isRequired(key) && !value) {
    error = 'This field is required';
  } else if (key === 'emailAddress' && !/\S+@\S+\.\S+/.test(value)) {
    error = 'Invalid email address';
  } else if ((key === 'homePhone' || key === 'mobilePhone') && !/^\d{3} \d{3} \d{4}$/.test(value)) {
    error = 'Phone number must be in format: ### ### ####';
  } else if (key === 'zip' && !/^\d{5}(-\d{4})?$/.test(value)) {
    error = 'Invalid ZIP code';
  } else if (key === 'state' && !states.includes(value)) {
    error = 'Please select a valid state';
  }

  if (index !== undefined) {
    errors[section][index][key] = error;
  } else {
    errors[section][key] = error;
  }
};

const validateStep = () => {
  let isValid = true;
  if (currentStep.value === 1) {
    Object.keys(formData.personalInfo).forEach(key => {
      validateField('personalInfo', key);
      if (errors.personalInfo[key]) isValid = false;
    });
  } else if (currentStep.value === 2) {
    if (formData.educationHistory.length === 0) {
      errors.educationHistory = [{general: 'At least one education entry is required'}];
      isValid = false;
    } else {
      formData.educationHistory.forEach((edu, index) => {
        Object.keys(edu).forEach(key => {
          validateField('educationHistory', key, index);
          if (errors.educationHistory[index][key]) isValid = false;
        });
      });
    }
  } else if (currentStep.value === 3) {
    if (formData.employmentHistory.length === 0) {
      errors.employmentHistory = [{general: 'At least one employment entry is required'}];
      isValid = false;
    } else {
      formData.employmentHistory.forEach((emp, index) => {
        Object.keys(emp).forEach(key => {
          validateField('employmentHistory', key, index);
          if (errors.employmentHistory[index][key]) isValid = false;
        });
      });
    }
  } else if (currentStep.value === 4) {
    if (!formData.signature) {
      errors.signature = 'Signature is required';
      isValid = false;
    } else {
      errors.signature = '';
    }
  }

  return isValid;
};

const formatPhoneNumber = (phoneNumber) => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
  if (match) {
    return !match[2] ? match[1] :
        !match[3] ? `${match[1]} ${match[2]}` :
            `${match[1]} ${match[2]} ${match[3]}`;
  }
  return phoneNumber;
};

const nextStep = () => {
  if (validateStep() && currentStep.value < 4) {
    currentStep.value++;
  }
};

const prevStep = () => {
  if (currentStep.value > 1) {
    currentStep.value--;
  }
};


const submitForm = async () => {
  if (validateStep()) {
    isLoading.value = true;
    submitError.value = null;
    console.log(formData);
    //let endpointUrl = `https://epstalent--partial.sandbox.my.salesforce-sites.com/submission/services/apexrest/job-application`;
    //let endpointUrl = `https://epstalent--testing.sandbox.my.salesforce-sites.com//services/apexrest/job-application`;
    let endpointUrl = `https://epstalent.my.salesforce-sites.com/services/apexrest/job-application`;
    try {
      const response = await axios.post(endpointUrl, formData);
      if (response.status === 200) {
        formSubmitted.value = true;
        console.log('Form submitted successfully:', response);
      }
    } catch (error) {
      console.error("There was an error!", error);
      submitError.value = 'An error occurred while submitting the form. Please try again.';
    } finally {
      isLoading.value = false;
    }
  }
};

/*
const fillTestData = () => {
  formData.personalInfo = {
    firstName: 'John',
    lastName: 'Doe',
    address1: '123 Test St',
    address2: 'Apt 4',
    city: 'Testville',
    state: 'TX',
    zip: '12345',
    homePhone: '123 456 7890',
    mobilePhone: '098 765 4321',
    emailAddress: 'john.doe@test.com'
  };

  formData.educationHistory = [{
    diplomaOrDegree: "Bachelor's Degree",
    schoolName: 'Test University',
    schoolCity: 'Eduville',
    schoolState: 'CA',
    startDate: '09/01/2015',
    endDate: '05/31/2019'
  }];

  formData.employmentHistory = [{
    companyName: 'Test Corp',
    city: 'Worktown',
    state: 'NY',
    startDate: '06/15/2019',
    endDate: '06/30/2023',
    jobTitle: 'Software Developer',
    jobDuties: 'Developed and maintained web applications'
  }];

  // Move to the last step of the form
  currentStep.value = 3;
};*/

</script>

<style scoped>


.container {
  max-width: 600px;
  padding: 20px;
  font-family: Arial, sans-serif;
  margin: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}


@media (min-width: 1200px) {
  .container {
    max-width: 600px;
  }
}


@media (min-width: 768px) {
  .container {
    max-width: 600px;
  }
}


@media (min-width: 992px) {
  .container {
    max-width: 600px;
  }
}

h1 {
  text-align: center;
  color: #333;
}

header {
  text-align: center;
  margin-bottom: 20px;
}

.header-logo {
  max-width: 200px;
  height: auto;
}

@media (max-width: 768px) {
  .header-logo {
    max-width: 150px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}


.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.row.no-gutters > [class^="col-"] {
  padding-right: 0;
  padding-left: 0;
}


.col-12, .col-md-3, .col-md-4, .col-md-6 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

@media (min-width: 768px) {
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.progress-bar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.progress-step {
  flex: 1;
  text-align: center;
  position: relative;
}

.progress-step::after {
  content: '';
  position: absolute;
  top: 15px;
  left: 50%;
  width: 100%;
  height: 2px;
  background-color: #e0e0e0;
  z-index: -1;
}

.progress-step:last-child::after {
  display: none;
}

.step-number {
  width: 30px;
  height: 30px;
  background-color: #e0e0e0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 5px;
}

.progress-step.active .step-number {
  background-color: #00A651;
  color: white;
}

.progress-step.complete .step-number {
  background-color: #0066B3;
  color: white;
}

.step-name {
  font-size: 0.8em;
  color: #666;
}

.form-group {
  margin-bottom: 15px;
}

h1, h2, h3 {
  color: #00A651;
}


label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #666;
  font-size: 0.8em;
}

input, textarea, select {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid #E0E0E0;

}

select {
  height: 35px;
}

.error {
  color: #D32F2F;
  font-size: 0.8em;
  margin-top: 5px;
}

button {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.prev-btn, .next-btn {
  background-color: #0066B3;
  color: white;
}

.prev-btn:hover, .next-btn:hover {
  background-color: #005699;
}

.submit-btn {
  background-color: #00A651;
  color: white;
}

.submit-btn:hover {
  background-color: #008c44; /* Slightly darker green for hover state */
}

.add-btn {
  background-color: #4CAF50;
  color: white;
  margin-top: 10px;
}

.add-btn:hover {
  background-color: #45a049;
}

.remove-btn {
  background-color: #f44336;
  color: white;
  margin-top: 10px;
}

.remove-btn:hover {
  background-color: #da190b;
}

.education-entry, .employment-entry {
  border: 1px solid #ddd;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 4px;
  position: relative;
}

.delete-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #f3f4f6;
  border: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  color: #ef4444;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-btn:hover {
  background: #ef4444;
  color: #fff;
}

.delete-btn .pi-trash {
  font-size: 1.2em;
}

.alert {
  padding: 15px;
  margin-top: 20px;
  border-radius: 4px;
}

.success {
  background-color: #d4edda;
  border-color: #c3e6cb;
  color: #155724;
}


.success-message {
  text-align: center;
  padding: 40px;
  background-color: #e8f5e9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.success-message h2 {
  color: #00A651;
  font-size: 2em;
  margin-bottom: 20px;
}

.success-message p {
  color: #333;
  font-size: 1.2em;
  margin-bottom: 30px;
}

.success-icon {
  width: 64px;
  height: 64px;
  margin-bottom: 20px;
}

.alert.error {
  background-color: #f8d7da;
  border-color: #f5c6cb;
  color: #721c24;
  padding: 15px;
  margin-top: 20px;
  border-radius: 4px;
}

.test-btn {
  background-color: #ff9800;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  margin-bottom: 20px;
  transition: background-color 0.3s ease;
}

.test-btn:hover {
  background-color: #f57c00;
}


.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #00A651;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.acknowledgement-text {
  margin-bottom: 20px;
}

.signature-box img {
  max-width: 100%;
  max-height: 100%;
}

.small-text {
  background-color: #f8f9fa;
  border: 1px solid #00A651;
  border-radius: 0.25rem;
  padding: .8rem;
  font-size: 0.875rem;
  color: #495057;
}

</style>