<!-- SignatureComponent.vue -->
<template>
  <div class="signature-component">
    <div class="signature-box" @click="openModal">
      <img v-if="modelValue" :src="modelValue" alt="Signature" />
      <span v-else>Click here to sign</span>
    </div>

    <Teleport to="body">
      <div v-if="showModal" class="modal">
        <div class="modal-content">
          <h3>Create Your Signature</h3>
          <div v-if="!signatureMethod" class="signature-method-selection">
            <button @click="selectMethod('draw')" class="method-button">Draw Signature</button>
            <button @click="selectMethod('type')" class="method-button">Type Signature</button>
          </div>

          <div v-if="signatureMethod === 'draw'" style="text-align: center;">
            <canvas ref="signaturePad" width="400" height="200"></canvas>
            <div class="button-group">
              <button @click="clearSignature" class="secondary-button">Clear</button>
            </div>
          </div>

          <div v-if="signatureMethod === 'type'">
            <input v-model="typedSignature" placeholder="Type your signature" class="signature-input" />
            <div class="typed-signature-preview">
              {{ typedSignature }}
            </div>
          </div>

          <div class="modal-buttons">
            <button @click="saveSignature" class="primary-button">Save</button>
            <button @click="closeModal" class="secondary-button">Cancel</button>
          </div>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import SignaturePad from 'signature_pad';

defineProps({
  modelValue: String,
});

const emit = defineEmits(['update:modelValue']);

const showModal = ref(false);
const signaturePadInstance = ref(null);
const signaturePad = ref(null);
const signatureMethod = ref(null);
const typedSignature = ref('');

const openModal = () => {
  showModal.value = true;
  signatureMethod.value = null;
  typedSignature.value = '';
};

const closeModal = () => {
  showModal.value = false;
  signatureMethod.value = null;
};

const selectMethod = (method) => {
  signatureMethod.value = method;
  if (method === 'draw') {
    setTimeout(() => {
      if (signaturePad.value) {
        signaturePadInstance.value = new SignaturePad(signaturePad.value, {
          backgroundColor: 'rgb(255, 255, 255)',
          penColor: 'rgb(0, 0, 0)'
        });
      }
    }, 0);
  }
};

const clearSignature = () => {
  if (signaturePadInstance.value) {
    signaturePadInstance.value.clear();
  }
};

const saveSignature = () => {
  let signatureData;
  if (signatureMethod.value === 'draw') {
    if (signaturePadInstance.value && !signaturePadInstance.value.isEmpty()) {
      signatureData = signaturePadInstance.value.toDataURL();
    } else {
      alert('Please provide a signature');
      return;
    }
  } else if (signatureMethod.value === 'type') {
    if (typedSignature.value) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = 400;
      canvas.height = 200;
      ctx.font = '56px "Cedarville Cursive", cursive';
      ctx.fillStyle = 'black';
      ctx.fillText(typedSignature.value, 10, 100);
      signatureData = canvas.toDataURL();
    } else {
      alert('Please type your signature');
      return;
    }
  } else {
    alert('Please select a signature method');
    return;
  }
  emit('update:modelValue', signatureData);
  closeModal();
};

// Cleanup
onMounted(() => {
  watch(() => showModal.value, (newVal) => {
    if (!newVal && signaturePadInstance.value) {
      signaturePadInstance.value.off();
    }
  });
});
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&display=swap');

.signature-component {
  //font-family: 'Cedarville Cursive', cursive;
  color: #333;
}

.signature-box {
  border: 2px solid #4CAF50;
  border-radius: 4px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.signature-box:hover {
  background-color: rgba(76, 175, 80, 0.1);
}

.signature-box img {
  max-width: 100%;
  max-height: 100%;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 450px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h3 {
  color: #4CAF50;
  margin-bottom: 20px;
  font-family: Arial, sans-serif;
}

canvas {
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.signature-method-selection {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.method-button {
  flex: 1;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 0px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: Arial, sans-serif;
  margin: 4px;
}

.method-button:hover {
  background-color: #45a049;
}

.signature-input {
  //width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  //font-family: 'Cedarville Cursive', cursive;
  font-size: 18px;
}

.typed-signature-preview {
  height: 100px;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  color: black;
  overflow: hidden;
  font-family: 'Cedarville Cursive', cursive;

}

.button-group {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: Arial, sans-serif;
}

.primary-button {
  background-color: #4CAF50;
  color: white;
}

.primary-button:hover {
  background-color: #45a049;
}

.secondary-button {
  background-color: #2196F3;
  color: white;
}

.secondary-button:hover {
  background-color: #1e88e5;
}
</style>